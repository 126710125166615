.table {
  &Container {
    overflow-x: auto;
    padding: 16px;

    * {
      box-sizing: border-box;
    }
  }

  &Cell {
    font-size: 14px;
    font-weight: 500;

    min-width: 128px;
    position: relative;

    // row name
    &:nth-child(1) {
      font-size: 20px;
      line-height: 28px;
    }

    // cells with input
    &:not(:nth-child(1)) {
      > * {
        display: grid;
        grid-template-columns: 44px 100px;
        height: 44px;
        border-radius: 0;
        width: 100%;

        input {
          width: 100%;
        }

        > *:first-child {
          display: grid;
          padding: 0;
          width: 100%;
          place-items: center;
          background: none;
          border-right: 1px solid var(--mantine-color-grayUI-1);
        }
      }
    }
  }

  &Row {
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    :not(:nth-child(1)) {
      align-items: center;
    }
  }

  &Head {
    @extend .tableRow;

    .tableCell:not(:nth-child(1)) {
      background: var(--mantine-color-grayUI-1);
      display: inline-flex;
      align-items: flex-end;

      white-space: pre-line;

      padding: 12px 16px 8px;
    }
  }
}
