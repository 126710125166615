.tabs {
  display: flex;
  gap: 44px;

  a {
    cursor: pointer;
  }
}

.tabActiveLink {
  color: var(--mantine-color-indigo-6);
  text-decoration: underline;
}

.card {
  background: var(--mantine-color-white);
  padding: 64px 50px 64px;
  border-radius: 10px;
}

.table {
  width: 100%;
  display: grid;
  gap: 16px;

  font-size: 14px;
  font-weight: 700;
  color: rgba(#06152b, 0.7);

  font-family: "Roboto";
  font-weight: 500;
}

.tableRow {
  display: grid;
  align-items: center;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  > *:first-child {
    text-align: left;
  }
}

.tableHeader {
  margin-bottom: 4px;

  > *:not(:first-child) {
    text-align: center;
  }
}

.withdrawAllContainer {
  width: 100%;
  display: flex;
}

.withdrawAllButton {
  margin-left: auto;
  height: 45px;
}
