.inputField {
  &Container {
    display: flex;

    border: 1px solid var(--mantine-color-grayUI-2);
    border-radius: 4px;

    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  &Label {
    width: 90px;
    padding: 12px 16px;
    background: var(--mantine-color-grayUI-2);
  }

  &Ticker {
    padding: 12px 16px;
    border-right: 1px solid var(--mantine-color-grayUI-2);
  }

  &InputContainer {
    position: relative;
    border: none;
    width: 100px;

    input {
      border: none;
      width: 100px;
    }
  }
}
