.cardsContainer {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  align-items: stretch;
}

.headContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
}

.headContainerRightColumn {
  display: flex;
  align-items: center;
  gap: 8px;
}

.headTitle {
  font-size: 20px;
}

.headSelect {
  max-width: 120px;
  min-width: 120px;

  input {
    height: 36px;
    font-weight: 500;
  }
}

.datePicker {
  input {
    height: 36px;
    font-weight: 500;
  }

  table {
    box-shadow: none;
    thead {
      background: none;
    }
  }
}

.ringFlexRow {
  display: flex;
}

.table {
  box-shadow: none;

  thead {
    background: none;
  }

  th {
    color: var(--mantine-color-grayUI-9) !important;
  }

  th,
  td {
    &:nth-child(2),
    &:nth-child(3) {
      text-align: right;
    }
  }
}

.modalButtonRow {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
