.root {
  height: 254px;
  width: 254px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--mantine-color-gray-2);
  border-radius: 12px;
  background-color: white;

  &[data-accept] {
    color: var(--mantine-color-white);
    background-color: var(--mantine-color-blue-6);
  }

  &[data-reject] {
    color: var(--mantine-color-white);
    background-color: var(--mantine-color-red-6);
  }
}






