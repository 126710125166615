.fieldTitle {
    margin-right: auto;
    margin-left: 0;
    color: gray;
    font-weight: bold;
    font-size: 14px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.fieldInfo {
    margin-left: auto;
    margin-right: 0;
    color: gray;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.cardHeader {
    font-size: 18px;
}