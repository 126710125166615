.input {
  &Controls {
    display: none;
    position: absolute;
    z-index: 2;
    right: 0;
    top: 50%;
    transform: translate(100%, -50%);
    width: 48px;
  }

  &Container {
    position: relative;
    width: 100px;

    input {
      border: none;
      width: 100px;
    }

    &Active {
      > *:nth-child(1) {
        border: 1px solid var(--mantine-color-violet-6);
        z-index: 1;
        position: absolute;
        top: -1px;
        left: -1px;
        height: calc(100% + 2px);
        width: 164px;
        background: white;
      }

      .inputControls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        button {
          width: 20px;
          height: 20px;
        }
      }
    }

    &Error {
      > *:nth-child(1) {
        border: 1px solid var(--mantine-color-red-6);
        background: var(--mantine-color-red-0);

        border-radius: 0 4px 4px 0;

        z-index: 1;
        position: absolute;
        top: -1px;
        left: -1px;
        height: calc(100% + 2px);
      }
    }
  }
}

.loader {
  display: none;
}

.loaderActive {
  position: absolute;
  display: grid;
  place-items: center;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
}
