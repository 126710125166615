
.selectedClientContainer {
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-md);
  background-color: var(--mantine-color-violet-0);
  border-radius: var(--mantine-radius-sm);
}

.selectedClientHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}