body {
  --color-background-content-area: #F8F9FA;
  --color-background-card: white;
}

@font-face {
  font-family: "Roboto";
  src: url('./shared/static/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url('./shared/static/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url('./shared/static/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: Bold;
  font-display: swap;
}
