.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  background: #f1f4fa;
  border-radius: 10px;
  color: #000000;

  height: 45px;
  padding: 0 10px 0 16px;
}

.button {
  color: var(--mantine-color-indigo-6);
  width: 40px;
  height: 40px;
  padding: 0;
}
