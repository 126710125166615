.root {
  width: 100%;
  display: flex;
}

.content {
  background-color: var(--color-background-content-area);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 12px;
  position: relative;
  border-radius: 24px 0 0 0;
  max-height: calc(100vh - 12px);
  overflow: scroll;
}