.form {
  display: grid;
}

.text {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ef452f;
}

.controlButtons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
