.inputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  background: #f1f4fa;
  border-radius: 10px;
  color: #000000;

  height: 45px;
  padding: 0 10px 0 16px;

  &,
  input {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
  }
  input {
    width: auto;
    background-color: transparent;
    border: none;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.white {
  background: #ffffff;
}
