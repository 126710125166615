.card {
  flex: 1 1 512px;
  width: 100%;
  min-height: 400px;
}

.loading {
  display: grid;
  place-items: center;
}
