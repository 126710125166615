.titleContainer {
  display: flex;
  align-items: center;
}

.filtersInputContainer {
  display: flex;
  height: 48px;
  align-items: stretch;
}

.filtersMenuButtonContainer {
  height: 48px;
  border: 1px solid var(--mantine-color-gray-4);
  border-left: 0px;
  border-radius: 0 var(--mantine-radius-sm) var(--mantine-radius-sm) 0;
  background-color: white;
  display: flex;
  align-items: center;
}

.searchInput {
  flex-grow: 1;
}

.searchInputField {
  height: 48px;
  border-radius: var(--mantine-radius-sm) 0 0 var(--mantine-radius-sm);
}

.searchRightSection {
  width: min-content;
  padding-right: var(--mantine-spacing-sm);
}

.filtersList {
  display: flex;
  gap: var(--mantine-spacing-sm);
}

.commentColumn {
  max-width: 180px;
  word-break: break-all;
}

.ipColumn {
  max-width: 200px;
  word-break: normal;
  font-size: 12px !important;
}

.dataColumn {
  white-space: pre-wrap;
  max-width: 230px;
  word-break: break-all;
  font-size: 13px !important;
}
