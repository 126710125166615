.switch {
  display: flex;
  justify-content: flex-end;
}

.headerCell {
  text-align: right;
  padding: var(--mantine-spacing-sm);
  font-weight: 500;
  font-size: var(--mantine-font-size-sm);
  &.header {
    text-align: left;
  }
}

.currencyIcon {
  width: 24px;
  color: var(--mantine-color-white);
  background-color: var(--mantine-color-black);
  border-radius: var(--mantine-radius-sm);
  padding: 4px;
}