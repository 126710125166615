.feesGroup {
  &Container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 16px;

    flex-wrap: wrap;

    margin-top: 32px;
  }

  &TitleContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    white-space: nowrap;
  }

  &InputGroup {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
  }
}
