@import "./TransferableValue.module";

.form {
  display: grid;
  gap: 24px;

  font-weight: 700;
  font-size: 14px;
}

.stepTitle {
  color: rgba(#3c7341, 0.7);
}

.fieldRow {
  display: grid;
  grid-template-columns: 2fr 3fr;
  align-items: center;
  gap: 28px;
}

.inputContainer {
  @extend .container;
  text-transform: uppercase;

  &,
  input {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
  }
  input {
    width: auto;
    background-color: transparent;
    border: none;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.select {
  background: #f1f4fa;
  border-radius: 10px;
  color: #000000;

  height: 45px;

  input {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 14px;
    padding: 0 10px 0 16px;
  }
}

.button {
  grid-column: 2 / 2;
  width: 100%;
  color: #fff;
}

.limits {
  color: rgba(#ef452f, 0.7);
}

.buttonsContainer {
  @extend .fieldRow;
  grid-template-columns: 1fr 1fr;
}
