.root {
  display: flex;
  flex-direction: column;
  gap: var(--mantine-spacing-md);
  justify-content: flex-end;
  align-items: center;
  width: 254px;
  height: 254px;
  border-radius: var(--mantine-radius-md);
  overflow-wrap: anywhere;
  background-color: var(--mantine-color-gray-0);
  padding: var(--mantine-spacing-md);
  &.empty {
    justify-content: center;
  }
}