.rateCard {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.rateRow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
}

.rateRowTitle {
  min-width: 136px;
  font-weight: 500;
}

.rateInputsContainer {
  display: flex;
  row-gap: 12px;
  column-gap: 40px;
  flex-wrap: wrap;
}

.rateRowButtonContainer {
  display: flex;

  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  border: 1px solid var(--mantine-color-grayUI-2);
  border-radius: 4px;

  > *:nth-child(1) {
    width: 90px;
    padding: 12px 16px;
    background: var(--mantine-color-grayUI-2);
  }

  > *:nth-child(2) {
    padding: 12px 16px;
    border-right: 1px solid var(--mantine-color-grayUI-2);
  }
}

.inputContainer {
  position: relative;
  width: 100px;

  input {
    border: none;
    width: 100px;
  }

  .inputControls {
    display: none;
    position: absolute;
    z-index: 2;
    right: 0;
    top: 50%;
    transform: translate(100%, -50%);
    width: 48px;
  }
}

.inputContainerActive {
  > *:nth-child(1) {
    border: 1px solid var(--mantine-color-violet-6);
    z-index: 1;
    position: absolute;
    top: -1px;
    left: -1px;
    height: calc(100% + 2px);
    width: 164px;
    background: white;
  }

  .inputControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    button {
      width: 20px;
      height: 20px;
    }
  }
}

.inputContainerError {
  > *:nth-child(1) {
    border: 1px solid var(--mantine-color-red-6);
    background: var(--mantine-color-red-0);

    border-radius: 0 4px 4px 0;

    z-index: 1;
    position: absolute;
    top: -1px;
    left: -1px;
    height: calc(100% + 2px);
  }
}

.loader {
  display: none;
}

.loaderActive {
  position: absolute;
  display: grid;
  place-items: center;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: white;
}
