.rightSection {
  display: flex;
  align-items: center;
  padding-right: var(--mantine-spacing-xs);
  gap: var(--mantine-spacing-xs);
  width: initial;
}

.clearIcon {
  width: 17px;
  height: 17px;
}

.errorNotFound {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--mantine-spacing-xl);
  background-color: var(--mantine-color-red-0);
  color: var(--mantine-color-red-6);
  border-radius: var(--mantine-radius-md);
  font-size: var(--mantine-font-size-xs);
}

