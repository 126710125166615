.root {
  font-weight: 400;
  background-color: white;
  border: 1px solid var(--mantine-color-gray-3);
}

.fieldName {
  font-size: 12px;
  color: var(--mantine-color-gray-6);
  text-transform: capitalize;
}

.value {
  text-transform: none;
  font-size: 14px;
  &.date {
    font-size: 12px;
  }
}

.rightSection {
  display: flex;
  align-items: center;
}

.closeIcon {
  width: 16px;
  height: 16px;
}